<template>
  <div class="rno-section market-facilitator">
    <div class="rno-section-inner">
      <div class="rno-section-hd">
        <h3 class="rno-section-tit">{{title}}</h3>
        <a class="rno-link rno-link-weak-arrow" href="/market/providerlist">查看更多</a>
      </div>
      <div class="rno-section-bd">
        <div class="rno-section-tit-m">综合服务商</div>
        <div class="market-facilitator-box-m">
            <div class="market-facilitator-list-m" style="width: 100%">
                <div class="market-facilitator-item-m"
                  v-for="(provider, index) in integratedProviderList"
                  :key="index"
                  v-if="activedIndex === index"
                  @click.prevent="goDetail(`providerdetail/${provider.cid}`)"
                  >
                    <div class="market-facilitator-item-head-m"
                    :style="`background-image:url(${provider.providerImg})`"
                    >
                        <img 
                          :src="provider.providerLogo" 
                          :alt="provider.providerName" 
                          class="company-logo-img-m" 
                          style="width:72px;height:36px;margin-left:-36px;margin-top:-18px"
                          >
                    </div>
                    <div class="market-facilitator-item-body-m">
                        <div class="market-facilitator-item-title-m">
                            {{provider.providerName}}
                        </div>
                        <div class="market-facilitator-item-text-m">
                            {{provider.providerDesc}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="market-facilitator-box" v-if="integratedProviderList && integratedProviderList.length">
          <div class="market-facilitator-main">
            <div class="market-facilitator-title">综合服务商</div>
            <div class="market-facilitator-main-inner" @mouseover="mouseEnterHandler()" @mouseout="mouseOutHandler()">
              <a class="market-facilitator-main-view" :style="backgroundStyle" :href="`/market/providerdetail/${activedProvider.cid}`">
                <img class="company-logo-img" v-if="activedProvider.providerLogo" :src="activedProvider.providerLogo" :alt="activedProvider.providerName" />
              </a>
              <div :class="['market-facilitator-main-box', numberClass]">
                <div
                  :class="['market-facilitator-main-item',{'actived': activedIndex === index} ]"
                  v-for="(provider, index) in integratedProviderList"
                  :key="index"
                  @mouseover="change_actived(index)"
                >
                  <i class="market-facilitator-main-logo" v-if="activedIndex !== index">
                    <img v-if="provider.providerLogo" :src="provider.providerLogo" :alt="provider.providerName">
                  </i>
                  <a class="market-facilitator-main-title" :href="`/market/providerdetail/${provider.cid}`">
                    {{provider.providerName}}
                  </a>
                  <div class="market-facilitator-main-desc">
                    {{provider.providerDesc}}
                  </div>
                  <div class="market-facilitator-main-text" v-if="showActivedProviderExperience && activedIndex === index">
                      行业经验：{{provider.providerExp < 0 ? '若干月' : provider.providerExp + '年以上'}}
                  </div>
                  <a
                    v-if="activedIndex === index"
                    class="rno-btn rno-btn-primary"
                    :href="`/market/providerdetail/${activedProvider.cid}`"
                    ><span class="rno-btn-text">查看详情</span></a
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="market-facilitator-side" v-if="activedProvider.partnerList && activedProvider.partnerList.length">
            <div class="market-facilitator-title">
              综合服务商推荐合作伙伴
            </div>

            <div class="market-facilitator-panel">
              <div
                class="market-facilitator-card"
                v-for="(partner, index) in activedProvider.partnerList"
                v-if="index < 3"
                :key="index"
              >
                <a class="market-facilitator-card-logo" :href="`/market/partnerdetail/${partner.partnerId}`">
                  <img :src="partner.partnerLogo" :alt="partner.partnerName" />
                </a>
                <div class="market-facilitator-card-cont">
                  <a class="market-facilitator-card-title" :href="`/market/partnerdetail/${partner.partnerId}`">
                    {{partner.partnerName}}
                  </a>
                  <div class="market-facilitator-card-desc">
                    行业经验：{{partner.partnerExp < 0 ? '若干月' : partner.partnerExp + '年以上'}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="market-facilitator-list-wrap" v-if="providerList">
          <div
            class="rno-lattice rno-lattice-cols-3 rno-lattice-cols-m-1 rno-lattice-cols-s-1 rno-lattice-without-margin"
          >
            <partner-simple-list class="rno-lattice-cell" :title="'元器件服务商'" :list="providerList.comList" :detail-route="'/market/providerdetail/${id}'" :show-experience="true"></partner-simple-list>
            <partner-simple-list class="rno-lattice-cell" :title="'硬件终端服务商'" :list="providerList.hardList" :detail-route="'/market/providerdetail/${id}'" :show-experience="true"></partner-simple-list>
            <partner-simple-list class="rno-lattice-cell" :title="'解决方案服务商'" :list="providerList.solutionList" :detail-route="'/market/providerdetail/${id}'" :show-experience="true"></partner-simple-list>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PartnerSimpleList from './PartnerSimpleList'
import { jump, getUrlParma } from '../../util/jumpPage'

export default {
  components: {
    PartnerSimpleList,
  },
  props: {
    title: {
      type: String,
      default: '服务商'
    },
    integratedProviderList: {
      type: Array
    },
    providerList: {
      type: Object,
      default: () => {
        return {
          comList: null,
          hardList: null,
          solutionList: null
        }
      }
    }
  },
  data() {
    return {
      activedIndex: 0,
      timer: 0
    }
  },
  computed: {
    activedProvider() {
      return (this.integratedProviderList && this.integratedProviderList[this.activedIndex]) || {}
    },
    backgroundStyle() {
      return this.activedProvider && this.activedProvider.providerImg ? `background-image:url(${this.activedProvider.providerImg});` : `background-image:none;`
    },
    numberClass() {
      let num = +this.integratedProviderList?.length
      if (num <= 1) {
        return 'only'
      }
      if (num === 2) {
        return 'two'
      }
      return ''
    },
    showActivedProviderExperience() {
      let num = +this.integratedProviderList?.length
      return num <= 2
    }
  },
  methods: {
    change_actived(index) {
      this.activedIndex = index
    },
    mouseEnterHandler() {
      clearTimeout(this.timer)
    },
    mouseOutHandler() {
      this.generateTimer()
    },
    goDetail(page) {
      jump(this, {
        page,
        deep: true,
      })
    },
    generateTimer() {
      const providerNum = this.integratedProviderList.length
      this.timer && clearTimeout(this.timer)
      // 数量大于1才轮播， 时间3s
      if (providerNum > 1) {
        this.timer = setTimeout(() => {
          this.activedIndex = ++this.activedIndex % providerNum
          this.generateTimer()
        }, 3000)
      }
    }
  },
  mounted() {
    this.generateTimer()
  }
}
</script>
<style lang="less" scoped>
.market-facilitator-main-desc {
  -webkit-line-clamp: 3;
}
</style>