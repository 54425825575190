<template>
  <div class="rno-3-hero-group market-recruit-hero">
    <div class="rno-3-hero">
      <div class="rno-3-hero-list">
        <div class="rno-3-hero-item" :style="backgroundStyle">
          <div class="rno-3-hero-item-bg"></div>
          <div class="rno-3-hero-item-inner">
            <div class="rno-3-hero-item-ctn">
              <h1 class="rno-3-hero-item-title">{{ currentConfig.title }}</h1>
              <div class="rno-3-hero-item-desc">
                <p>{{ currentConfig.desc }}</p>
              </div>
            </div>
            <div v-if="currentConfig.buttonList && currentConfig.buttonList.length > 0" :class="['rno-3-hero-item-btns', `rno-3-hero-item-list-${currentConfig.buttonList.length}`]">
              <button class="rno-btn rno-btn-primary rno-btn-l" v-for="(item, index) in currentConfig.buttonList" :key="index" @click.prevent="clickButton(item.name, item.params)">
                <span class="rno-btn-text">{{ item.text }}</span>
              </button>
            </div>
          </div>
          <div class="market-solution-top market-solution-section" v-if="currentConfig.showChosen">
            <div class="market-solution-top-title">{{ currentConfig.chosenTitle }}</div>
            <component :is="current" :list-data="listData"></component>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'Banner',
  props: {
    current: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapState('commonData', {
      pageConfig: (s) => s.pageConfig
    }),
    currentConfig() {
      return this.pageConfig[this.current] || {}
    },
    backgroundStyle() {
      return this.currentConfig.background ? `background-image:url(${this.currentConfig.background});` : `background-image:none;`
    },
    listData() {
      const SELF = this
      let current = this.current
      if (!this.currentConfig.showChosen || !current) {
        return {
          total: 0,
          list: []
        }
      }

      // 换成首字母大写。。。
      current = current.substr(0, 1).toUpperCase() + current.substr(1)
      let pageSpace = `data${current}`
      let chosenList = SELF.$store.state[pageSpace].chosenList

      return chosenList
    }
  },
  components: {
    solution: function (resolve) {
      require(['_c/banner/Solution.vue'], resolve)
    },
    module: function (resolve) {
      require(['_c/banner/Module.vue'], resolve)
    },
    provider: function (resolve) {
      require(['_c/banner/Provider.vue'], resolve)
    },
    partner: function (resolve) {
      require(['_c/banner/Provider.vue'], resolve)
    },
  },
  methods: {
    clickButton: function (btnName, btnParams) {
      this.$emit('click-button', btnName, btnParams)
    }
  }
}
</script>
