<template>
  <div v-if="list && list.length">
      <div class="market-facilitator-title">{{title}}</div>
      <div class="market-facilitator-panel">
          <div 
            class="market-facilitator-card"
            v-for="(item, index) in list"
            :key="index"
            v-if="index<limit"
          >
              <a class="market-facilitator-card-logo" :href="detailRoute.replace('${id}', item.id)">
                  <img v-if="item.companyLogo" :src="item.companyLogo" :alt="item.companyName">
              </a>
              <div class="market-facilitator-card-cont">
                  <a class="market-facilitator-card-title" :href="detailRoute.replace('${id}', item.id)">
                      {{item.companyName}}
                  </a>
                  <div class="market-facilitator-card-desc" v-if="showExperience">
                    行业经验：{{item.industryExperience < 0 ? '若干月' : item.industryExperience+'年以上'}}
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '合作伙伴'
    },
    list: {
      type: Array,
      default: () => null
    },
    limit: {
      type: Number,
      default: 3
    },
    detailRoute: {
      type: String,
      default: '/market/partnerdetail/${id}'
    },
    showExperience: {
      type: Boolean,
      default: false
    }
  },
}
</script>