<template src="./index.html"> </template>

<script>
import { mapState, mapActions } from 'vuex'
import Banner from '_c/banner/Index'
import MessageBox from '_c/MessageBox'
import ProviderSection from '../home/ProviderSection'
import PartnerSection from '../home/PartnerSection'

const STATUS_JOINED = 3
const COOPERATION_TYPE_NAMES = {
  '1': '合作伙伴',
  '2': '服务商'
}

export default {
  async asyncData({ store }) {
    const DISPATCH = store.dispatch
    return await Promise.all([
      DISPATCH('commonData/setCurrentPage', 'guide'),
      DISPATCH('dataPartner/getIntegratedProviderList'),
      DISPATCH('dataPartner/getHomeProviderList'),
    ])
  },
  components: {
    Banner,
    MessageBox,
    ProviderSection,
    PartnerSection,
  },
  data() {
    return {
      msgBoxData: null,
      isH5: false
    }
  },
  computed: {
    ...mapState('commonData', {
      guideFlowIntro: (s) => s.guideFlowIntro,
      guidePartnerIntro: (s) => s.guidePartnerIntro,
      guidePartnerTypeIntro: (s) => s.guidePartnerTypeIntro,
      guideBottom: (s) => s.guideBottom,
      guideUrls: (s) => s.guideUrls
    }),
    ...mapState('dataUserInfo', {
      statusData: (s) => s.settleStatusData
    }),
    ...mapState('dataPartner', {
      providerList: (s) => s.homeProviderList,
      partnerList: (s) => s.homePartnerList,
      integratedProviderList: (s) => s.integratedProviderList,
    }),
  },
  mounted() {
    let _that = this
    //判断是否在小程序内
    typeof(wx) != 'undefined' && wx.miniProgram.getEnv(function(res) {
      this.isH5 = res.miniprogram
    })
  },
  methods: {
    clickButton: function (btnName, btnParams) {
      console.log('clickButton', btnName, btnParams)
      if (!this.guideUrls || !btnName || !this.guideUrls[btnName]) {
        return
      }

      //todo: handle event
      if (this.statusData && this.statusData.settleStatus) {
        const appliedTypeName = COOPERATION_TYPE_NAMES[this.statusData.cooperationType] || '合作伙伴'
        const hasJoined = this.statusData.settleStatus === STATUS_JOINED

        let msgBoxData = {
          type: 'error',
          title: hasJoined ? `您已入驻物联网市场的${appliedTypeName}` : `您已申请过成为${appliedTypeName}`,
          desc: '',
          buttonList: [{ name: 'ok', text: '知道了', params: null }]
        }
        if (btnParams && btnParams.cooperationType === this.statusData.cooperationType) {
          //重复申请同一个
          msgBoxData.desc = hasJoined ? `您已入驻物联网市场的${appliedTypeName}` : `您已提交过成为${appliedTypeName}的问卷，请耐心等待。`
        } else {
          //又申请另外一个
          msgBoxData.desc = hasJoined ? `服务商和合作伙伴只可选择一种，您已入驻物联网市场的${appliedTypeName}` : `服务商和合作伙伴只可选择一种，您已提交过成为${appliedTypeName}的问卷，请耐心等待。`
        }

        //弹窗
        this.msgBoxData = msgBoxData

        return
      }
      //若在小程序里则跳转到问卷小程序
      if (this.isH5 && typeof(wx) != 'undefined') {
        wx.navigateToMiniProgram({
          appId: 'wxebadf544ddae62cb',
          path: `pages/survey/index?sid=${this.guideUrls[btnName].sid}&hash=${this.guideUrls[btnName].hash}`,
          success(res) {
            console.log('open success')
          },
          fail() {}
        })
      } else {
        window.open(`https://wj.qq.com/s2/${this.guideUrls[btnName].sid}/${this.guideUrls[btnName].hash}`)
      }
      
    },
    clickMessageBox: function (btnName, btnParams) {
      //关闭弹窗
      this.msgBoxData = null
    }
  }
}
</script>
