<template>
  <div class="rno-section bg-color market-facilitator market-cooperation">
    <div class="rno-section-inner">
      <div class="rno-section-hd">
        <h3 class="rno-section-tit">{{title}}</h3>
        <a class="rno-link rno-link-weak-arrow" href="/market/partnerlist">查看更多</a>
      </div>
      <div class="rno-section-bd">
        <div class="market-facilitator-list-wrap" v-if="partnerList">
          <div class="rno-lattice rno-lattice-cols-3 rno-lattice-cols-m-1 rno-lattice-cols-s-1 rno-lattice-without-margin">
            <partner-simple-list class="rno-lattice-cell" :title="'通讯器件'" :list="partnerList.comList" :detail-route="'/market/partnerdetail/${id}'"></partner-simple-list>
            <partner-simple-list class="rno-lattice-cell" :title="'硬件终端'" :list="partnerList.hardList" :detail-route="'/market/partnerdetail/${id}'"></partner-simple-list>
            <partner-simple-list class="rno-lattice-cell" :title="'解决方案'" :list="partnerList.solutionList" :detail-route="'/market/partnerdetail/${id}'"></partner-simple-list>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PartnerSimpleList from './PartnerSimpleList'

export default {
  props: {
    title: {
      type: String,
      default: '合作伙伴'
    },
    partnerList: {
      type: Object,
      default: () => {
        return {
          comList: null,
          hardList: null,
          solutionList: null
        }
      }
    }
  },
  components: {
    PartnerSimpleList,
  },
}
</script>