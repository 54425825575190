<template>
  <div>
    <div class="rno-mask"></div>
    <div class="rno-modal rno-modal-auto">
      <div class="rno-modal-inner">
        <div class="rno-modal-bd">
          <div class="rno-modal-confirm">
            <div class="rno-modal-confirm-object"><i :class="['rno-modal-confirm-icon', `rno-modal-confirm-icon-${iconType}`]"></i></div>
            <div class="rno-modal-confirm-body">
              <h3 class="rno-modal-confirm-title">{{ title }}</h3>
              <div class="rno-modal-confirm-desc">{{ desc }}</div>
            </div>
          </div>
        </div>
        <footer class="rno-modal-ft">
          <button class="rno-btn rno-btn-primary" v-for="(item, index) in buttonList" :key="index" @click.prevent="clickButton(item.name, item.params)">
            <span class="rno-btn-text">{{ item.text }}</span>
          </button>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    //弹窗类型，用来控制icon，支持：info/success/warning/error/pending
    type: {
      type: String,
      default: 'info'
    },
    title: {
      type: String,
      default: ''
    },
    desc: {
      type: String,
      default: ''
    },
    buttonList: {
      type: Array,
      default: () => [
        {
          name: 'ok',
          text: '确定',
          params: null
        }
      ]
    }
  },
  computed: {
    iconType() {
      const iconMap = {
        info: 'infoblue',
        success: 'success',
        warning: 'warning',
        error: 'error',
        pending: 'pending'
      }
      return iconMap[this.type] || iconMap['info']
    }
  },
  methods: {
    clickButton: function (btnName, btnParams) {
      this.$emit('click-button', btnName, btnParams)
    }
  }
}
</script>
